import { createRouter, createWebHistory } from 'vue-router'
const routes = [
    // Routes pour CheckoutPage avec différents chemins
    {
        path: '/pay/:tokenID',
        name: 'CheckoutPage',
        component: () => import('./screens/CheckoutPage.vue')
    },
    {
        path: '/pay/standard/:tokenID',
        name: 'CheckoutPageStandard',
        component: () => import('./screens/CheckoutPage.vue')
    },
    {
        path: '/pay/standard/token/:tokenID',
        name: 'CheckoutPageStandardToken',
        component: () => import('./screens/CheckoutPage.vue')
    },
    // Route pour CheckoutPageMor
    {
        path: '/order/:orderID',
        name: 'CheckoutPageMor',
        component: () => import('./screens/CheckoutPageMor.vue')
    },
    // Route pour CheckoutPageRequest
    {
        path: '/request/:tokenID',
        name: 'CheckoutPageRequest',
        component: () => import('./screens/CheckoutPageRequest.vue')
    },
    // Route pour NotFound
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('./screens/NotFound.vue')
    },
]


const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

export default router