

import { createApp } from 'vue/dist/vue.esm-bundler';
import i18n from './i18n'
import router from './router'
import Start from './screens/Start'
import styles from '/public/styleguide.css'

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
library.add(faSpinner)
library.add(faExclamation)
library.add(faBan)
library.add(faAngleDown)
library.add(faAngleUp)

const app = createApp(Start)

app.component("font-awesome-icon", FontAwesomeIcon)
app.use(router)
app.use(i18n)
app.mount('#app')
