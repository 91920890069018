import { createI18n } from 'vue-i18n'

const messages = {}
const availableLanguages = []
const localesContext = require.context('@/locales', true, /\.json$/);
localesContext.keys().forEach((key) => {
  const lang = key.match(/([a-z-]+)/i)[0];
  messages[lang] = localesContext(key);
  availableLanguages.push(lang);
})

const getDefaultLanguage = () => {
  const browserLanguage = navigator.language.split('-')[0];
  return availableLanguages.find(lang => lang === browserLanguage) ? browserLanguage : 'en';
}
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: getDefaultLanguage(),
  messages
})

export default i18n;
